import { createGlobalStyle, css } from 'styled-components'

const GlobalStyle = createGlobalStyle`
	${({ theme }) => css`
		:root {
			font-size: 62.5%;
		}

		* {
			margin: 0px;
			padding: 0px;

			box-sizing: border-box;

			font: ${theme.fontWeight.regular} 1em 'Open Sans', sans-serif;
		}
		/* .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
			border: none;
		} */

		html,
		body {
			width: 100%;
			background-color: ${theme.palette.white};
			color: ${theme.palette.gray[900]};
			font-size: 1.6rem;
		}
	`};

	.section-container {
		flex: 1;

		padding: 15px;
		max-width: 1200px;

		margin: auto;
	}


	@media(width: 425px) {
		.section-container {
			max-width: 425px;

		}
	}	

	@media(width: 768px) {
		.section-container {
			max-width: 768px;

		}
	}	

	@media(width: 1024px) {
		.section-container {
			max-width: 1024	px;

		}
	}

		
`

export default GlobalStyle
