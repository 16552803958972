import Text from '@components/Core/Text'
import useController from '@hooks/useController'
import { TextField as MUITextField } from '@material-ui/core'
import { ComponentProps } from 'react'
import { FieldValues, UseControllerProps } from 'react-hook-form'

type Props = UseControllerProps<FieldValues, string> &
	ComponentProps<typeof MUITextField>

function TextField(props: Props) {
	const {
		name,
		control,
		defaultValue,
		rules,
		shouldUnregister,
		...imputProps
	} = props

	const { field, fieldState } = useController({
		name,
		control,
		defaultValue,
		rules,
		shouldUnregister
	})

	const { error } = fieldState

	return (
		<MUITextField
			{...imputProps}
			{...field}
			size='small'
			color='primary'
			fullWidth
			inputRef={field.ref}
			error={!!error}
			helperText={error?.message && <Text $scale={12}>{error?.message}</Text>}
			variant='outlined'
		/>
	)
}

export default TextField
