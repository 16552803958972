import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	pedding: false,
	error: null,
	itens: {} as { [keys: string]: number }
}

const cartSlice = createSlice({
	name: 'cart',
	initialState,
	reducers: {
		increment: (state, action: { payload: string }) => {
			state.itens[action.payload] += 1
		},
		decrement: (state, action: { payload: string }) => {
			state.itens[action.payload] -= 1
		},
		remove: (state, action: { payload: string }) => {
			delete state.itens[action.payload]
		},
		add(state, action: { payload: string }) {
			state.itens[action.payload] = 1
		}
	}
})

export const { actions: cartActions, reducer: cartReducer } = cartSlice
