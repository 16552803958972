import ROUTES, { IRoute } from './routes'

const generateRoutes = (routes: Readonly<IRoute[]>,	pathToRedirect = '',currentPath = '') => {
	
	const rotas: any[] = []

	// eslint-disable-next-line no-restricted-syntax
	for (const route of routes) {
		const { path, Component, disabled, routes: subRoutes, param } = route

		const routePath = `${currentPath}/${param || path}`

		// eslint-disable-next-line no-continue
		if (disabled) continue

		if (Component) {
			rotas.push({
				path: routePath,
				element: <Component />
			})
		}

		if (subRoutes) {
			rotas.push(...generateRoutes(subRoutes, pathToRedirect, routePath))
		}
	}

	return rotas
}

export const PrivateRoutes = () => {
	return generateRoutes(ROUTES.private, '/')
}

export const PublicRoutes = () => {
	return generateRoutes(ROUTES.public, '/')
}
