import { createSlice } from '@reduxjs/toolkit'
import { Categoria } from './type'

const initialState = {
	pendent: false,
	error: null,
	categorias: [] as Categoria[]
}

const categoriaSlice = createSlice({
	name: 'categorias',
	initialState,
	reducers: {
		setList(state, action: { payload: Categoria[] }) {
			state.categorias = action.payload
			return state
		}
	}
})

export const { actions: categoriaActions, reducer: categoriaReducer } = categoriaSlice
