import MainHeader from './Main-header'
import TopHeader from './Top-header'

const Header = () => {
	return (
		<>
			<TopHeader />
			<MainHeader />
			{/* <NavHeader /> */}
		</>
	)
}

export default Header
