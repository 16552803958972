import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import Text from '../../components/Core/Text'
import Footer from './components/Footer'
import Header from './components/Header'
import * as S from './styles'

function Drawer() {
	return (
		<div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
			<Header />

			<S.Main className='section-container'>
				<Suspense fallback={<Text>carregando...</Text>}>
					<Outlet />
				</Suspense>
			</S.Main>

			<Footer />
		</div>
	)
}

export default Drawer
