import { createTheme } from '@material-ui/core/styles'

export const palette = {
	primary: {
		main: '#008F89',
		light: '#008F8970'
	},
	secondary: {
		main: '#EB5B25',
		light: '#EB5B2570'
	},
	complementary: {
		main: '#820B48',
		light: '#820B4870'
	},
	success: {
		main: '#006A00',
		light: '#006A0070'
	},
	warning: {
		main: '#FFC107',
		light: '#FFC10770'
	},
	danger: {
		main: '#940000',
		light: '#94000070'
	},
	info: {
		main: '#0081B9',
		light: '#0081B970'
	},
	pending: {
		main: '#ff690f',
		light: '#ff690f70'
	},

	dark: {
		main: '#1E293B'
	},
	gray: {
		'100': '#FAFCFF',
		'200': '#E2E8F0',
		'300': '#CBD5E1',
		'400': '#94A3B8',
		'500': '#64748B',
		'600': '#475569',
		'700': '#334155',
		'800': '#1E293B',
		'900': '#0F172A'
	},

	white: '#FFFFFF'
}

export const fontSize = {
	10: '1.0rem',
	12: '1.2rem',
	14: '1.4rem',
	16: '1.6rem',
	18: '1.8rem',
	20: '2.0rem',
	22: '2.2rem',
	25: '2.5rem',
	28: '2.8rem',
	32: '3.2rem',
	40: '4.0rem',
	50: '5.0rem'
}

export const lineHeight = {
	16: '1.6rem'
}

export const fontWeight = {
	ligth: `300`,
	regular: `400`,
	bold: `700`
}

export const MUITheme = createTheme({
	palette
})

const theme = {
	palette,
	fontWeight,
	fontSize,
	lineHeight
}

export default theme
