import GenericController from '../GenericControlller'
import dt from './dt.json'

class Product extends GenericController {
	async index() {
		return dt
	}

	async grouped() {
		return dt.reduce((acc: any, curr) => {
			const category = curr.category

			if (!acc[category]) {
				acc[category] = []
			}

			acc[category].push(curr)

			return acc
		}, {})
	}
}

export default Product
