import { IconBaseProps } from 'react-icons'
import { AiOutlineGift, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { BiLogOut, BiMinus, BiPlus, BiShoppingBag, BiTrash } from 'react-icons/bi'
import {
	BsArrowLeft,
	BsArrowRight,
	BsCartPlus,
	BsGear,
	BsInstagram,
	BsListCheck,
	BsSearch,
	BsWhatsapp
} from 'react-icons/bs'
import { FaFacebookF, FaLinkedinIn, FaRegUser } from 'react-icons/fa'
import { HiMenu, HiOutlineShoppingCart } from 'react-icons/hi'
import { RiArrowDownSFill, RiArrowUpSFill, RiDashboardLine } from 'react-icons/ri'

import { IoIosClose } from 'react-icons/io'

import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

import { BsFilter, BsThreeDotsVertical, BsTwitter } from 'react-icons/bs'

import { GoPlusSmall } from 'react-icons/go'

const IconsList = {
	['dashboard-line']: (props: IconBaseProps) => <RiDashboardLine {...props} />,
	['shopping']: (props: IconBaseProps) => <BiShoppingBag {...props} />,
	['bs-cart-alt']: (props: IconBaseProps) => <HiOutlineShoppingCart {...props} />,
	['list-check']: (props: IconBaseProps) => <BsListCheck {...props} />,
	['instagran']: (props: IconBaseProps) => <BsInstagram {...props} />,
	['arrow-left']: (props: IconBaseProps) => <BsArrowLeft {...props} />,
	['arrow-right']: (props: IconBaseProps) => <BsArrowRight {...props} />,
	['whatsapp']: (props: IconBaseProps) => <BsWhatsapp {...props} />,
	['facebook']: (props: IconBaseProps) => <FaFacebookF {...props} />,
	['twitter']: (props: IconBaseProps) => <BsTwitter {...props} />,
	['linkedin']: (props: IconBaseProps) => <FaLinkedinIn {...props} />,
	['search']: (props: IconBaseProps) => <BsSearch {...props} />,
	['user-group-add']: (props: IconBaseProps) => <AiOutlineUsergroupAdd {...props} />,
	['menu']: (props: IconBaseProps) => <HiMenu {...props} />,
	['gift']: (props: IconBaseProps) => <AiOutlineGift {...props} />,
	['user']: (props: IconBaseProps) => <FaRegUser {...props} />,
	['gear']: (props: IconBaseProps) => <BsGear {...props} />,
	['triangle-down']: (props: IconBaseProps) => <RiArrowDownSFill {...props} />,
	['triangle-up']: (props: IconBaseProps) => <RiArrowUpSFill {...props} />,
	['logout']: (props: IconBaseProps) => <BiLogOut {...props} />,
	['plus']: (props: IconBaseProps) => <GoPlusSmall {...props} />,
	['trash']: (props: IconBaseProps) => <BiTrash {...props} />,
	['close']: (props: IconBaseProps) => <IoIosClose {...props} />,
	['vertical-dots']: (props: IconBaseProps) => <BsThreeDotsVertical {...props} />,
	['test']: (props: IconBaseProps) => <MdOutlineKeyboardArrowDown {...props} />,
	['filter']: (props: IconBaseProps) => <BsFilter {...props} />,
	['cart-minus']: (props: IconBaseProps) => <BiMinus {...props} />,
	['cart-plus']: (props: IconBaseProps) => <BiPlus {...props} />,
	['cart-add']: (props: IconBaseProps) => <BsCartPlus {...props} />
} as const

interface Props extends IconBaseProps {
	name: keyof typeof IconsList
}

function Icons(props: Props) {
	const { name, ...iconProps } = props

	const Icon = IconsList[name]

	return <Icon {...iconProps} />
}

export default Icons
