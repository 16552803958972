import { lazy, LazyExoticComponent } from 'react'
import { RouteProps } from 'react-router-dom'

export interface IRoute {
	path: string
	param?: string
	Component?: LazyExoticComponent<(props?: RouteProps) => JSX.Element>
	disabled?: boolean
	routes?: IRoute[]
}

interface IRoutes {
	public: Readonly<IRoute[]>
	private: Readonly<IRoute[]>
}

const ROUTES: IRoutes = {
	public: [
		// login
		{
			path: 'login',
			Component: lazy(() => import('@pages/public/Login'))
		},
		{
			path: 'register',
			Component: lazy(() => import('@pages/public/Register'))
		},
		{
			path: '',
			Component: lazy(() => import('@pages/public/Home'))
		},
		{
			path: 'cart',
			Component: lazy(() => import('@pages/public/Cart'))
		},
		/* 		{
			path: 'reset-password',
			Component: lazy(() => import('../pages/public/ResetPassword'))
		}, */
		{
			path: '*',
			Component: lazy(() => import('@pages/NotFound'))
		}
	],
	private: [
		{
			path: 'my-profile',
			Component: lazy(() => import('@pages/private/MyProfile'))
		}
	]
}

export default ROUTES
