import { Grid } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Grid).attrs({
	container: true
})``

export const Item = styled(Grid).attrs({
	item: true
})``

export const ScreenContainer = styled.header`
	background: #008f89;

	svg {
		cursor: pointer;
	}

	* {
		color: ${({ theme }) => theme.palette.white};
	}
`

export const SearchContainer = styled.div`
	display: flex;
	background: ${({ theme }) => theme.palette.white};

	border-radius: 3px;
`
export const SearchIcon = styled.div`
	display: flex;
	align-items: center;
	padding: 0.85rem;

	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;

	background: ${({ theme }) => theme.palette.secondary.main};
`
export const ContextIcon = styled.div`
	position: relative;
	display: inline-block;
`
export const Count = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${({ theme }) => theme.palette.secondary.main};

	font-size: 10px;
	font-weight: 700;

	width: 17px;
	height: 17px;
	border-radius: 50%;

	position: absolute;
	top: -7px;
	right: -8px;
`

export const PersonalMenu = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
`
