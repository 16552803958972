import { Grid } from '@mui/material'
import styled from 'styled-components'

interface ContainerIconMediaProps {
  variant: 'facebook' | 'twitter' | 'linkedin' | 'whatsapp' | 'instagran'
}

const colorIcon = {
  facebook : '#3B5998',
  twitter : '#0081B9',
  linkedin : '#007AB9',
  instagran : '#B04687',
  whatsapp : '#006A00'
}


export const ContainerFooter = styled.footer`

	display: flex;
	flex-direction: column;
	justify-content: space-between;

	height: 440px;
	

	padding: 1.9rem 15.6rem;
	background: ${({ theme }) => theme.palette.primary.main};

	@media (max-width: 1200px) {
		padding: 0.7rem 5rem;
	}
	@media (max-width: 900px) {
		height: 400px;
	}
	@media (max-width: 600px) {
		padding: 0.7rem 1rem;
	}

	* {
		color: ${({ theme }) => theme.palette.white};
	}
`

export const Container = styled(Grid).attrs({
	container: true
})``

export const Item = styled(Grid).attrs({
	item: true
})``

export const ContainerIconMedia = styled.div<ContainerIconMediaProps>`
	width: 36px;
	height: 36px;

	display: flex;
	justify-content: center;
	align-items: center;

	background: ${({ variant }) => colorIcon[variant]};
	border-radius: 50%;
	cursor: pointer;
`

export const ContainerEscrevaSe = styled.div`

	display: flex;
	align-items: center;
	flex: 1;

	padding: 8px;
	border-radius: 45px;

	background-color: ${({theme}) => theme.palette.gray[200]};
`
