import {
	Control,
	FieldValues,
	useController as RHFUseController,
	UseControllerProps,
	UseControllerReturn
} from 'react-hook-form'

type MyControl = Control<FieldValues, any> & {
	onAfterChange?: () => void
}

type Props = UseControllerProps<FieldValues, string> & {
	control?: MyControl
}

type Return = UseControllerReturn<FieldValues, string>

function useController(props: Props): Return {
	const control = props.control

	const { field, ...restHookReturn } = RHFUseController(props)

	const { onChange: fieldOnChange, ...restField } = field

	const onChange = (value: any) => {
		fieldOnChange(value)

		if (control?.onAfterChange) control.onAfterChange()
	}

	return {
		...restHookReturn,
		field: {
			...restField,
			onChange
		}
	}
}

export default useController
