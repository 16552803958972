import Text from '@src/components/Core/Text'
import * as S from './styles'

const Copyright = () => {
	return (
		<S.ContainerCopyright>
			<Text $scale={14}>Copyright © 2023 La feira</Text>
		</S.ContainerCopyright>
	)
}

export default Copyright
