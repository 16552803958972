import { AxiosResponse } from 'axios'
import GenericController from '../GenericControlller'

import * as T from './types'

const resource = 'cliente-endereco'

export default class Cliente_Endereco extends GenericController {
	index = async (clienteId: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/client/${clienteId}`)
	}

	get = async (id: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/${id}`)
	}

	store = async (data: T.Cliente_endereco): Promise<AxiosResponse> => {
		return this.axios.post(`/${resource}`, data)
	}

	update = async (data: T.Cliente_endereco): Promise<AxiosResponse> => {
		const { id, ...rest } = data

		
		return this.axios.put(`/${resource}/${id}`, rest)
	}

	markAsDefault = async({padrao,id, id_cliente}: {
		padrao: string,
		id: number,
		id_cliente: number,
	}): Promise<AxiosResponse> => {
		return this.axios.put(`/${resource}/${id}`, {
			padrao,
			id_cliente
		})
	}

	delete = async (id: number): Promise<AxiosResponse> => {
		return this.axios.delete(`/${resource}/${id}`)
	}
}
