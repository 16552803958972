import { makeStyles } from '@material-ui/core'
import { useMediaQuery, useTheme as useThemeMui } from '@mui/material'
import Icons from '@src/components/Core/Icons'
import Text from '@src/components/Core/Text'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import Copyright from './Copyright'
import * as S from './styles'

const useStyles = makeStyles({
	textField: {
	  '& .MuiOutlinedInput-root': {
		border: 'none', // Remove a borda
		'&:hover': {
		  border: 'none', // Remove a borda também quando passar o mouse sobre o campo
		},
		'&.Mui-focused': {
		  border: 'none', // Remove a borda quando o campo estiver em foco
		},
	  },
	  '& .MuiOutlinedInput-notchedOutline': {
		border: 'none', // Remove a borda
		'&:hover': {
		  border: 'none', // Remove a borda também quando passar o mouse sobre o campo
		},
		'&.Mui-focused': {
		  border: 'none', // Remove a borda quando o campo estiver em foco
		},
	  }
	},
})

const Footer = () => {
	const navigate = useNavigate();

	const themeMui = useThemeMui()

	const isTablet = useMediaQuery(themeMui.breakpoints.down('md'))
	

	const categorias = useMemo(() => {
		return ['Frutas', 'Verduras', 'Legumes', 'Orgânicos', 'Kits', 'Diversos']
	},[])

	const conteudos = useMemo(() => {
		return [ {label: 'Minha conta', path: '/my-profile'}]
	},[])


	return (
		<S.ContainerFooter>
			<div className='section-container'>
			<S.Container
				spacing={2}
			>
				<S.Item
					container
					gap={4}
				
					md={6}
					xs={12}
				>
					<S.Item>
						<Text
							$bold
							$scale={28}
						>
							La feira
						</Text>
					</S.Item>

					<S.Item
						container
						gap={3}
					>
						<Text $scale={14}>
							Lorem ipsum dolor sit amet. Sit quibusdam maiores et nostrum
							cumque qui excepturi tenetur sed magni eius. Qui culpa itaque id
							esse facilis
						</Text>

						<S.Item
							container
							justifyContent={isTablet ? 'center' : ''}
							gap={isTablet ? 2 : 1}
							xs={12}
						>
							<S.ContainerIconMedia variant='facebook'>
								<Icons
									name='facebook'
									size={22}
								/>
							</S.ContainerIconMedia>

							<S.ContainerIconMedia variant='twitter'>
								<Icons
									name='twitter'
									size={22}
								/>
							</S.ContainerIconMedia>
							<S.ContainerIconMedia variant='linkedin'>
								<Icons
									name='linkedin'
									size={22}
								/>
							</S.ContainerIconMedia>
							<S.ContainerIconMedia variant='instagran'>
								<Icons
									name='instagran'
									size={22}
								/>
							</S.ContainerIconMedia>
							<S.ContainerIconMedia variant='whatsapp'>
								<Icons
									name='whatsapp'
									size={22}
								/>
							</S.ContainerIconMedia>
						</S.Item>
					</S.Item>
				</S.Item>

				{!isTablet && (
					<>
						<S.Item
							container
							md={3}
						>
							<S.Item xs={12}>
									<Text
										$bold
										$scale={18}
									>
										Categorias
									</Text>
							</S.Item>

							{!!categorias.length && categorias.map(elem => (
								<S.Item md={6} xs={12}>
									<Text $scale={14}>{elem}</Text>
								</S.Item>		
							))}
						
						</S.Item>

						<S.Item
							container
				
							md={3}
						>
								<S.Item md={12}>
									<Text
										$bold
										$scale={18}
									>
										Conteúdo
									</Text>
								</S.Item>

								{!!conteudos.length && conteudos.map(({label,path}) => (
									<S.Item style={{
										cursor:'pointer'
									}}md={12} onClick={() => navigate(path)}>
										<Text $scale={14}>{label}</Text>
									</S.Item>	
									
								))}					
						</S.Item>
					</>
				)}

				{/* <S.Item
					container
					xl={3}
					lg={3}
					md={4}
				>
					<S.Item
						container
						gap={2}
					>
						{!isTablet && (
							<S.Item>
								<Text
									$bold
									$scale={18}
								>
									Fique por dentro
								</Text>
							</S.Item>
						)}

						<S.Item
							container
							gap={2}
						>
							{!isTablet && (
								<Text $scale={14}>
									Cadastre-se e receba nossas ofertas por e-mail semanalmente
								</Text>
							)}
							<S.Item xs={12}>
								<S.ContainerEscrevaSe>
									<TextField
										name='email'
										control={control}
										fullWidth
										placeholder='Digite seu e-mail'
										style={{
											fontSize: '18px',
											padding: '0 0 0 1rem',
											flex: 1
										}}
										className={classes.textField}
									/>
									<Button
										style={{
											borderRadius: 'inherit',
											textTransform: 'none',
											minWidth: '100px'
										}}
									>
										<Text
											$scale={12}
											$bold
											style={{
												color: '#fff'
											}}
										>
											Inscreva-se
										</Text>
									</Button>
								</S.ContainerEscrevaSe>
							</S.Item>
						</S.Item>
					</S.Item>
				</S.Item> */}
			</S.Container>

			<Copyright />
			</div>
		</S.ContainerFooter>
		
	)
}

export default Footer
