import { Grid } from '@mui/material'
import styled from 'styled-components'

export const ScreenContainer = styled.div`
	background: #017a75;

	* {
		color: ${({ theme }) => theme.palette.white};
	}
`

export const Container = styled(Grid).attrs({
	container: true
})``
export const Item = styled(Grid).attrs({
	item: true
})``

export const Nav = styled.nav`
	display: flex;
	gap: 16px;

	p:not(:last-child) {
		border-right: solid white 1px;
		padding-right: 1.6rem;
	}
`
