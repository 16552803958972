import { AxiosResponse } from 'axios'
import GenericController from '../GenericControlller'

import * as T from './types'

const resource = 'categoria'

export default class Empresa extends GenericController {
	index = async (params?: T.FilterParams): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}`, (params && { 
			params
		}))
	}

	get = async (id: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/${id}`)
	}

	store = async (data: T.Categoria): Promise<AxiosResponse> => {
		return this.axios.post(`/${resource}`, data)
	}

	update = async (data: T.Categoria): Promise<AxiosResponse> => {
		return this.axios.put(`/${resource}/${data.id}`, data)
	}

	delete = async (id: number): Promise<AxiosResponse> => {
		return this.axios.delete(`/${resource}/${id}`)
	}
}
