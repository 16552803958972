import Axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import Categoria from './controllers/Categoria'
import Cliente from './controllers/Cliente'
import Cliente_Carteira from './controllers/Cliente_carteira'
import Cliente_Endereco from './controllers/Cliente_endereco'
import Launcher from './controllers/Launcher'
import Product from './controllers/Product'

class IApi {
	axios: AxiosInstance
	access_token: string | null
	token_type: string | null
	Cliente: Cliente
	ClienteEndereco: Cliente_Endereco
	ClienteCarteira: Cliente_Carteira
	Product: Product
	Categoria: Categoria
	Launcher: Launcher

	constructor() {
		this.axios = Axios.create({
			baseURL: process.env.REACT_APP_BASE_URL
		})

		this.Cliente = new Cliente(this.axios)
		this.ClienteEndereco = new Cliente_Endereco(this.axios)
		this.ClienteCarteira = new Cliente_Carteira(this.axios)
		this.Product = new Product(this.axios)
		this.Categoria = new Categoria(this.axios)
		this.Launcher = new Launcher(this.axios)

		this.access_token = ''
		this.token_type = ''

		if (localStorage.getItem('@acess_token')) {
			this.access_token = localStorage.getItem('@acess_token')
		}

		if (localStorage.getItem('@token_type')) {
			this.token_type = localStorage.getItem('@token_type')
		}

		this.axios.interceptors.request.use(function (config) {
			const token_type = localStorage.getItem('@token_type')
			const access_token = localStorage.getItem('@access_token')

			if (token_type && access_token) {
				if (config && config.headers) {
					config.headers['Authorization'] = `${token_type} ${access_token}`

					return config
				}
			}

			return config
		})

		this.axios.interceptors.response.use(
			(response: AxiosResponse) => {
				return response
			},
			(error: AxiosError) => {
				if (error.response) {
					switch (error.response.status) {
						case 403:
							this.logout()
							break
					}
				}

				return Promise.reject(error)
			}
		)


	}

	removeAcessToken() {
		return delete this.axios.defaults.headers.common['access_token']
	}

	replaceAcessToken(acess_token: string) {
		this.access_token = acess_token
	}

	replaceTokenType(token_type: string) {
		this.token_type = token_type
	}

	async login(email: string, password: string) {
		const data = await this.axios
			.post('/login', {
				email,
				password
			})
			.then((data) => {
				const { data: autenticacao } = data
				const { token_type, access_token, email, nome } = autenticacao

				localStorage.setItem('@access_token', access_token)
				localStorage.setItem('@token_type', token_type)
				localStorage.setItem('@email', email)
				localStorage.setItem('@nome', nome)

				this.replaceAcessToken(access_token)
				this.replaceTokenType(token_type)

				return {
					data: autenticacao,
					err: null
				}
			})
			.catch((err) => {
				return {
					data: null,
					err: err.response.data
				}
			})
		return data
	}

	async logout() {
		const data = await this.axios
			.post('/sair')
			.then((data) => {
				if (localStorage.getItem('@access_token')) {
					window.localStorage.removeItem('@access_token')
				}

				if (localStorage.getItem('@token_type')) {
					window.localStorage.removeItem('@token_type')
				}

				if (localStorage.getItem('@email')) {
					window.localStorage.removeItem('@email')
				}

				if (localStorage.getItem('@nome')) {
					window.localStorage.removeItem('@nome')
				}

				this.removeAcessToken()

				return {
					data,
					err: null
				}
			})
			.catch((err) => {
				return {
					data: null,
					err: err.response.data
				}
			})

		return data
	}
}

export default new IApi()
