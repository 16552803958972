import { Button, ButtonBase, Menu, MenuItem } from '@material-ui/core'
import { useMediaQuery, useTheme } from '@mui/material'
import Icons from '@src/components/Core/Icons'
import AutoComplete from '@src/components/Core/Inputs/AutoComplete'
import TextField from '@src/components/Core/Inputs/TextInput'
import Text from '@src/components/Core/Text'
import { useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'

import { makeStyles } from '@material-ui/core'
import api from '@src/api'
import { DynamicImage } from '@src/components/Core/DynamicImage'
import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

const useStyles = makeStyles({
	textField: {
		'& .MuiOutlinedInput-root': {
			border: 'none', // Remove a borda
			'&:hover': {
				border: 'none' // Remove a borda também quando passar o mouse sobre o campo
			},
			'&.Mui-focused': {
				border: 'none' // Remove a borda quando o campo estiver em foco
			}
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: 'none', // Remove a borda
			'&:hover': {
				border: 'none' // Remove a borda também quando passar o mouse sobre o campo
			},
			'&.Mui-focused': {
				border: 'none' // Remove a borda quando o campo estiver em foco
			}
		}
	}
})

const MainHeader = () => {
	const navigate = useNavigate()

	const { control } = useForm()

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
	const isTablet = useMediaQuery(useTheme().breakpoints.down('md'))

	const authentication = useMemo(() => {
		if (localStorage.getItem('@nome')) {
			return {
				name: localStorage.getItem('@nome'),
				email: localStorage.getItem('@email')
			}
		}
		return null
	}, [localStorage.getItem('@nome')])

	const handleLogout = async () => {
		await api.logout()
	}
	const classes = useStyles()

	const options = [
		{ label: 'Todas as categorias', id: 1 },
		{ label: 'Frutas', id: 2 }
	]

	const handleRedirectLogin = () => {
		return navigate('/login')
	}

	const handleRedirectCart = () => {
		return navigate('/cart')
	}

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleRedirecCart = () => {
		return navigate('/cart')
	}

	return (
		<S.ScreenContainer>
			<div className='section-container'>
				{!isMobile ? (
					<S.Container>
						{/* <S.Item
							container
							alignItems='center'
							justifyContent={'flex-end'}
							md={2}
							xs={3}
							paddingRight={2}
						>
							<S.Item>
								<Text $scale={12}>Contatos</Text>
								<S.Item
									container
									gap={'2px'}
								>
									<Icons
										name='whatsapp'
										size={16}
									/>
									<Text
										$bold
										$scale={12}
									>
										(85) 99818-3632
									</Text>
								</S.Item>
							</S.Item>
						</S.Item> */}

						<S.Item md={2} style={{
							cursor: 'pointer'
						}} onClick={() => navigate('/')}>
							<DynamicImage srcPath='/logo/logo-versao2.svg' alt="Logo-versão 2"/>
						</S.Item>

						<S.Item
							container
							alignItems='center'
							justifyContent={'flex-end'}
							xs={9}
							md={10}
						>
							{!isTablet && (
								<S.Item xs={7}>
									<S.SearchContainer>
										<AutoComplete
											name='Search'
											options={options}
											getLabel={(option) => option.label}
											getValue={(option) => option.label}
											defaultValue={'Todas as categorias'}
											control={control}
											style={{
												width: '30rem'
											}}
											className={classes.textField}
										/>

										<TextField
											name='search'
											control={control}
											placeholder='Buscar por produtos'
											className={classes.textField}
										/>
										<S.SearchIcon>
											<Icons
												name='search'
												size={16}
											/>
										</S.SearchIcon>
									</S.SearchContainer>
								</S.Item>
							)}

							<S.Item
								container
								direction={'row'}
								alignItems={'center'}
								justifyContent={'flex-end'}
								gap={1}
								xs={3}
							>
								{authentication ? (
									<>
										<S.Item>
											<Text
												// $bold
												$scale={12}
												style={{ marginLeft: 5 }}
											>
												{authentication.name}
											</Text>
										</S.Item>

										<S.Item>
											<S.PersonalMenu>
												<ButtonBase
													id='Button-Menu'
													onClick={handleClick}
												>
													<MdOutlineKeyboardArrowDown
														size={20}
														color='white'
													/>
												</ButtonBase>
												<Menu
													open={open}
													id='Button-Menu'
													anchorEl={anchorEl}
													onClose={handleClose}
												>
													{/* <MenuItem onClick={handleClose}>Meu Perfil</MenuItem> */}
													<MenuItem onClick={() => handleLogout()}>Sair</MenuItem>
												</Menu>
											</S.PersonalMenu>
										</S.Item>
									</>
								) : (
									<Button onClick={handleRedirectLogin}>
										<Icons
											name='user'
											size={20}
										/>
										<S.Item>
											<Text
												// $bold
												$scale={12}
												style={{ marginLeft: 5 }}
											>
												Login/Registro
											</Text>
										</S.Item>
									</Button>
								)}
							</S.Item>

							<S.Item
								container
								alignItems={'center'}
								justifyContent={'end'}
								gap={1}
								xs={1}
								md={1}
							>
								<Button
									variant='outlined'
									onClick={handleRedirecCart}
								>
									<S.ContextIcon>
										<Icons
											name='bs-cart-alt'
											size={22}
										/>
										<S.Count>10</S.Count>
									</S.ContextIcon>
									{/* <S.Item>
										<Text $scale={12}>Carrinho</Text>
										<Text
											$scale={12}
											$bold
										>
											R$52,11
										</Text>
									</S.Item> */}
								</Button>
							</S.Item>
						</S.Item>
					</S.Container>
				) : (
					<S.Container>
						<S.Item xs={6}>
							<Icons
								name='menu'
								size={24}
							/>
						</S.Item>
						<S.Item
							container
							justifyContent={'flex-end'}
							gap={'16px'}
							xs={6}
						>
							<Icons
								name='user'
								size={20}
							/>
							<S.Item>
								<S.ContextIcon onClick={handleRedirectCart}>
									<Icons
										name='bs-cart-alt'
										size={22}
									/>
									<S.Count>10</S.Count>
								</S.ContextIcon>
							</S.Item>
						</S.Item>
					</S.Container>
				)}
			</div>
		</S.ScreenContainer>
	)
}

export default MainHeader
