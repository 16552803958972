import { useMediaQuery, useTheme } from '@mui/material'
import Icons from '@src/components/Core/Icons'
import Text from '@src/components/Core/Text'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import * as S from './styles'
const TopHeader = () => {
	const navigate = useNavigate();

	const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))

	const conteudos = useMemo(() => {
		return [ {label: 'Minha conta', path: 'my-profile'}]
	},[])

	return (
		<S.ScreenContainer>
			<div className='section-container'>
				{!isMobile ? (
					<S.Container justifyContent={'space-between'}>
						<S.Item
							container
							alignItems='center'
							gap='1.32rem'
							xs={4}
						>
							<Text $scale={12}>Siga-nos no</Text>
							<Icons
								name='instagran'
								size={16}
							/>
							<Icons
								name='whatsapp'
								size={16}
							/>
						</S.Item>

						<S.Item
							container
							alignItems='center'
							justifyContent={'flex-end'}
							gap='1.32rem'
							xs={8}
						>
							<S.Nav>
								{!!conteudos.length && conteudos.map(({label,path}) => (
									<S.Item style={{
										cursor:'pointer'
									}} onClick={() => navigate(path)}>
										<Text $scale={12}>{label}</Text>
									</S.Item>	
									
								))}	

								{/* <Text $scale={12}>Sobre Nós</Text>
								<Text $scale={12}>Contatos</Text>
								<Text $scale={12}>FAQs</Text> */}
							</S.Nav>
						</S.Item>
					</S.Container>
				) : null}
			</div>
		</S.ScreenContainer>
	)
}

export default TopHeader
