import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles'
import Drawer from '@src/modules/Drawer'
import { Provider } from 'react-redux'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ThemeProvider } from 'styled-components'
import GlobalStyle from './assets/styles/global'

import 'react-toastify/dist/ReactToastify.min.css'
import theme, { MUITheme } from './assets/styles/theme'

import { PersistGate } from 'redux-persist/integration/react'
import { PrivateRoutes, PublicRoutes } from './routes'
import store, { persistor } from './store'

function App() {
	const router = createBrowserRouter([
		{
			path: '/',
			element: <Drawer />,
			children: [...PublicRoutes(),...PrivateRoutes()]
		}
	])

	return (
		<Provider store={store}>
			<PersistGate
				loading={null}
				persistor={persistor}
			>
				<MUIThemeProvider theme={MUITheme}>
					<ThemeProvider theme={theme}>
						<GlobalStyle />
						<ToastContainer />

						<RouterProvider router={router} />
					</ThemeProvider>
				</MUIThemeProvider>
			</PersistGate>
		</Provider>
	)
}

export default App
