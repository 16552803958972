import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { categoriaReducer } from './ducks/categoria'
import { productReducer } from './ducks/product'
import { cartReducer } from './slices/cart'

const persistConfig = {
	key: 'root',
	storage
}

const rootReducer = combineReducers({
	cart: cartReducer,
	product: productReducer,
	categoria: categoriaReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
	reducer: persistedReducer
})

export default store

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
