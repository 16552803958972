import { createSlice } from '@reduxjs/toolkit'
import { Product } from './type'

const initialState = {
	pendent: false,
	error: null,
	products: [
		{
			id: '1',
			imgUrl: 'a',
			name: 'a',
			price: 10.5
		},
		{
			id: '2',
			imgUrl: 'b',
			nbme: 'b',
			price: 10.5
		},
		{
			id: '3',
			imgUrl: 'c',
			name: 'c',
			price: 10.5
		}
	] as Product[]
}

const productSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {
		setList(state, action: { payload: Product[] }) {
			state.products = action.payload
			return state
		}
	}
})

export const { actions: productActions, reducer: productReducer } = productSlice
