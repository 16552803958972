import useController from '@hooks/useController'
import { Autocomplete as MUIAutocoplete, TextField } from '@mui/material'
import { ComponentProps, useMemo } from 'react'
import { FieldValues, UseControllerProps } from 'react-hook-form'

type Props<T> = UseControllerProps<FieldValues, string> &
	Omit<ComponentProps<typeof MUIAutocoplete>, 'renderInput'> & {
		options: Array<T>
		getLabel: (data: T) => string
		getValue: (data: T) => unknown
	}

function AutoComplete<T>(props: Props<T>) {
	const {
		name,
		control,
		defaultValue,
		rules,
		shouldUnregister,
		getValue,
		getLabel,
		options,
		...restProps
	} = props

	const { field, fieldState } = useController({
		name,
		control,
		defaultValue,
		rules,
		shouldUnregister
	})

	const { error } = fieldState

	const optionsToRender = useMemo(() => {
		return options.map((option: T) => ({
			...option,
			label: getLabel(option)
		}))
	}, [])

	return (
		<MUIAutocoplete
			{...restProps}
			{...field}
			options={optionsToRender}
			onChange={(_, value) => {
				field.onChange(getValue(value))
			}}
			disablePortal
			fullWidth
			size='small'
			renderInput={(params) => (
				<TextField
					{...params}
					inputRef={field.ref}
					error={!!error}
					helperText={error?.message}
				/>
			)}
		/>
	)
}

export default AutoComplete
