import { AxiosResponse } from 'axios'
import GenericController from '../GenericControlller'

import * as T from './types'

const resource = 'clientes'

export default class Cliente extends GenericController {

	index = async (params?: T.FilterParams): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}`,  (params && { params }))
	}

	get = async (id: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/${id}`)
	}

	store = async (data: T.Cliente): Promise<AxiosResponse> => {
		return this.axios.post(`/${resource}`, data)
	}

	update = async (data: T.Cliente): Promise<AxiosResponse> => {
		const { id, ...rest } = data
		return this.axios.put(`/${resource}/${id}`, rest)
	}

	delete = async (id: number): Promise<AxiosResponse> => {
		return this.axios.delete(`/${resource}/${id}`)
	}
}
