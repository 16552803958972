import styled from "styled-components";

export const ContainerCopyright = styled.div`

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 16px 0;
  margin: 16px 0;

  border-top: 1px solid #eaeaea;

`