import theme from 'src/assets/styles/theme'
import styled, { css } from 'styled-components'

interface Props {
	$bold?: boolean
	$scale?: keyof typeof theme.fontSize
}

const Text = styled.p<Props>`
	${({ theme, $bold, $scale }) => css`
		${$bold && `font-weight: ${theme.fontWeight.bold};`}
		${$scale && `font-size: ${theme.fontSize[$scale]}`}
	`};
`

export default Text
