import { AxiosResponse } from 'axios'
import GenericController from '../GenericControlller'

import * as T from './types'

const resource = 'cliente-carteira'

export default class Cliente_Carteira extends GenericController {
	index = async (clienteId: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/client/${clienteId}`)
	}

	get = async (id: number): Promise<AxiosResponse> => {
		return this.axios.get(`/${resource}/${id}`)
	}

	store = async (data: T.Cliente_carteira): Promise<AxiosResponse> => {
		return this.axios.post(`/${resource}`, data)
	}

	update = async (data: T.Cliente_carteira): Promise<AxiosResponse> => {
		const { id, ...rest } = data
		return this.axios.put(`/${resource}/${id}`, rest)
	}

	delete = async (id: number): Promise<AxiosResponse> => {
		return this.axios.delete(`/${resource}/${id}`)
	}
}
